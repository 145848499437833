/** @format */

import $ from "jquery";
import { AbstractComponent, ComponentLoader } from "../AbstractComponent";
import { StockLoaderComponent } from "./StockLoaderComponent";
import { ThreeSpinnerComponent } from "./ThreeSpinnerComponent";
import { getContext, sleep } from "../toolkit";

let closeTag: string = "data-close-lightbox";

export class LightboxComponent extends AbstractComponent {
    public static selector: string = "lightbox-component";
    public contentElem: JQuery<HTMLDivElement>;
    private backgroundElem: JQuery<HTMLDivElement>;
    private openLightbox: string;
    private savedContent: JQuery<HTMLElement>;
    private progressElem: JQuery<HTMLElement>;
    public callback;
    public hideCallback;

    public init(): void {
        this.contentElem = this.componentElement.find(
            "[data-lightbox-content]"
        );
        this.backgroundElem = this.componentElement.parent();
        this.progressElem = this.componentElement.find(
            "[data-lightbox-progress]"
        );
        this.setupListeners();
        this.checkForPrePop();
    }

    private checkForPrePop(): void {
        let context = getContext();

        if (!context) {
            return;
        }

        let prePop = context["pop_modal"];

        if (prePop) {
            this.display(prePop);
        }
    }

    private setupListeners(): void {
        $(document).on(
            "click",
            `[${closeTag}], [${closeTag}-persistent]`,
            () => {
                this.hide();
            }
        );
        $(document).on("click", "[data-pop-modal]", event => {
            let intendedModal = $(event.target).attr("data-pop-modal");
            this.display(intendedModal);
        });
    }

    public setContent(key: string) {
        let contentElem = $(`[data-help-content=${key}]`);

        if (!contentElem.length) {
            console.error(`No content found for key: ${key}`);
            return;
        } else if (contentElem.length > 1) {
            contentElem = contentElem.first();
        }

        let contentClone = contentElem.clone();

        this.openLightbox = key;
        this.savedContent = contentClone.children();

        if (contentElem.attr("data-no-save") == undefined) {
            contentElem.empty();
        }

        this.contentElem.empty();
        this.contentElem.append(this.savedContent);

        if (contentElem.attr("data-no-close") != undefined) {
            this.hideCloses();
        } else {
            this.showCloses();
        }

        if (contentElem.attr("data-small") != undefined) {
            this.componentElement.addClass("lightbox--small");
        } else {
            this.componentElement.removeClass("lightbox--small");
        }

        let extraClass = contentElem.attr("data-extra-class");

        if (extraClass) {
            this.componentElement.addClass(extraClass);
        }

        if (this.callback) {
            this.callback();
        }
    }

    public setContentRaw(content: string): void {
        this.contentElem.html(content);
    }

    public show() {
        if (this.progressElem) {
            this.progressElem.css("width", "0");
        }

        this.backgroundElem.fadeIn();
    }

    public async hide() {
        this.backgroundElem.fadeOut();

        if (this.openLightbox) {
            await sleep(500);

            let contentElem = $(`[data-help-content=${this.openLightbox}]`);

            if (contentElem.attr("data-no-save") == undefined) {
                contentElem.empty();
                contentElem.append(this.savedContent);
            } else {
                this.contentElem.empty();
            }

            this.openLightbox = undefined;
            this.savedContent = undefined;
            this.componentElement.attr("class", "lightbox");
        }

        if (this.hideCallback) {
            this.hideCallback();
            this.hideCallback = undefined;
        }
    }

    public showCloses() {
        let inContentCloses = this.contentElem.find(`[${closeTag}]`);
        let allLightboxCloses = this.backgroundElem.find(`[${closeTag}]`);

        allLightboxCloses.not(inContentCloses).show();
    }

    public hideCloses() {
        let inContentCloses = this.contentElem.find(`[${closeTag}]`);
        let allLightboxCloses = this.backgroundElem.find(`[${closeTag}]`);

        allLightboxCloses.not(inContentCloses).hide();
    }

    public async display(
        content: string,
        raw?: boolean,
        time?: number,
        instantly: boolean = true
    ): Promise<void> {
        if (this.openLightbox) {
            await this.hide();
        }

        if (raw) {
            this.setContentRaw(content);
            this.showCloses();
        } else {
            this.setContent(content);
        }

        if (instantly) {
            this.show();
        }

        let form = this.contentElem.find("form");

        if (form.length) {
            (form as any).validate();
        }

        let threeSpinnerElem = this.contentElem.find(
            "[three-spinner-component]"
        );

        if (threeSpinnerElem.length) {
            new ComponentLoader([ThreeSpinnerComponent]);

            let threeSpinnerInst = this.DI.getComponentInstanceByElement(
                threeSpinnerElem
            ) as ThreeSpinnerComponent;
            await threeSpinnerInst.run();

            this.hide();
        }

        let stockLoaderElem = this.contentElem.find("[stock-loader-component]");

        if (stockLoaderElem.length) {
            new ComponentLoader([StockLoaderComponent]);

            let stockLoaderInst = this.DI.getComponentInstanceByElement(
                stockLoaderElem
            ) as StockLoaderComponent;
            await stockLoaderInst.run();

            this.hide();
        }

        if (time && this.progressElem) {
            await sleep(500);
            this.progressElem.animate({ width: "100%" }, { duration: time });
            await sleep(time + 500);
            this.hide();
        }
    }

    static async getDisplay(
        content: string,
        raw?: boolean,
        time?: number,
        hideCallback?: Function
    ): Promise<void> {
        let inst = LightboxComponent.getInst() as LightboxComponent;
        await inst.display(content, raw, time);

        if (hideCallback) {
            inst.hideCallback = hideCallback;
        }
    }

    static async getHide(content?: string): Promise<void> {
        let inst = LightboxComponent.getInst() as LightboxComponent;

        if (content && inst.openLightbox != content) {
            return;
        }

        await inst.hide();
    }
}
