/** format **/

import $ from "jquery";
import "../polyfill";
import {AbstractComponent} from "../AbstractComponent";
import {autocompleteDetails, autocompleteSearch} from "../requests/post";
import {getContext} from "../toolkit";

let timer;

export class AutocompleteAddressComponent extends AbstractComponent {
    public static selector: string = "autocomplete-address-component";
    private inputElem: JQuery<HTMLInputElement>;
    private line2InputElem: JQuery<HTMLInputElement>;
    private cityInputElem: JQuery<HTMLInputElement>;
    private stateInputElem: JQuery<HTMLInputElement>;
    private postalInputElem: JQuery<HTMLInputElement>;
    private verifyTokenInputElem: JQuery<HTMLInputElement>;
    private resultsElem: JQuery<HTMLDivElement>;
    private resultsLoaderElem: JQuery<HTMLDivElement>;

    public init(): void {
        if (getContext()["autocomplete_disabled"]) {
            return;
        }

        this.inputElem = this.componentElement.find("[name=line_1]");
        this.line2InputElem = this.componentElement.find("[name=line_2]");
        this.cityInputElem = this.componentElement.find("[name=city]");
        this.stateInputElem = this.componentElement.find("[name=state]");
        this.postalInputElem = this.componentElement.find("[name=postal_code]");
        this.verifyTokenInputElem = this.componentElement.find("[name=verify_token]");
        this.resultsElem = this.componentElement.find("[data-address-results]");
        this.resultsLoaderElem = this.componentElement.find("[data-address-results-loader]");

        this.setupListeners();
    }

    private setResults(results: object[], force?: boolean): void {
        if (!this.inputElem.is(":focus") && !force) {
            this.resultsElem.hide();
            return;
        }

        this.resultsElem.find(".autocomplete-address__result").remove();

        if (results.length === 0) {
            this.resultsElem.hide();
            return;
        }

        for (let result of results) {
            let drilldown = result["drilldown"];
            let resultElem = $(`<div class="autocomplete-address__result">${result["label"]}</div>`);

            resultElem.on("click", async () => {
                this.resultsLoaderElem.show();

                if (drilldown) {
                    await this.getResults(result["label"], result["id"], true);
                    this.resultsLoaderElem.hide();
                    return;
                } else {
                    await this.fillAddress(result["id"]);
                }

                this.resultsLoaderElem.hide();
                this.resultsElem.hide();
            });

            this.resultsElem.append(resultElem);
        }

        this.resultsElem.show();
    }

    private async fillAddress(placeId: string): Promise<void> {
        let details = await autocompleteDetails(placeId);

        this.inputElem.val(details["line_1"]);
        this.line2InputElem.val(details["line_2"]);
        this.cityInputElem.val(details["city"]);
        this.stateInputElem.val(details["county"]);
        this.postalInputElem.val(details["postcode"]);

        if (this.verifyTokenInputElem) {
            this.verifyTokenInputElem.val(details["verify_token"]);
        }
    }

    public setupListeners(): void {
        this.inputElem.on("keydown", () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                this.getResults();
            }, 300);
        });

        $("[data-address-results-close]").on("click", () => {
            this.resultsElem.hide();
        });
    }

    public async getResults(query?: string, container?: string, force?: boolean): Promise<void> {
        if (!this.inputElem.is(":focus") && !force) {
            return;
        }

        query = query || this.inputElem.val() as string;

        if (query.length < 3) {
            this.resultsElem.hide();
            return;
        }

        let results = await autocompleteSearch(query, container);
        this.setResults(results, force);
    }
}
