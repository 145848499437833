import $ from "jquery";

const handleFeatures = () => {
  const activeClass = 'active'
  const featureItems = $(".list-features__item")
  const images = $(".list-features__images img")
  const mobileImages = $(".list-features__content > .features_list > img")

  let index = 0
  const total = featureItems.length

  setInterval(() => {
    featureItems[index].classList.remove(activeClass)
    images[index].classList.remove(activeClass)
    mobileImages[index].classList.remove(activeClass)

    index = index === total - 1 ? 0 : index + 1

    featureItems[index].classList.add(activeClass)
    images[index].classList.add(activeClass)
    mobileImages[index].classList.add(activeClass)
  }, 15 * 1000)
}

const handlePrice = () => {
  const activeClass = 'active'
  const switcher = $(".price__toggle > div")
  const premiumPriceElement = $(".premium_price > span")
  const premiumPlusPriceElement = $(".premium_plus_price > span")
  const dataCurrency = $("[data-price-currency]")

  let index = 0
  const premiumPriceUSD = ['$4.95', '$4.13']
  const premiumPlusPriceUSD = ['$6.95', '$5.79']

  const premiumPriceCAD = ['$4.95', '$4.13']
  const premiumPlusPriceCAD = ['$7.95', '$6.63']

  const premiumPrice = ['£4.95', '£4.13']
  const premiumPlusPrice = ['£6.95', '£5.79']

  const currency = dataCurrency.data("price-currency")
  const premiumData = currency === "USD" ? premiumPriceUSD : currency === "CAD" ? premiumPriceCAD : premiumPrice
  const premiumPlusData = currency === "USD" ? premiumPlusPriceUSD : currency === "CAD" ? premiumPlusPriceCAD : premiumPlusPrice

  switcher.on('click', () => {
    index = index ? 0 : 1

    if (index) {
      switcher.addClass(activeClass)
    } else {
      switcher.removeClass(activeClass)
    }

    premiumPriceElement.text(premiumData[index])
    premiumPlusPriceElement.text(premiumPlusData[index])
  })

}

export const setup = () => {
  handleFeatures()
  handlePrice()
}