/** @format */

import "../polyfill";
import $ from "jquery";
import "jquery-validation";
import "../extra_validators";

import {
    firePixels,
    getContext,
    getUrlParam,
    localPrice,
    ordinalSuffix,
    replacePlaceholders,
    scrollToError,
    scrollToTop,
    sleep,
} from "../toolkit";
import { LightboxComponent } from "../components/LightboxComponent";
import { createAccount, deleteAccount, paypalUrl } from "../requests/post";
import { address, addressLazy, checkout, email } from "../requests/save";
import { Chive, ChiveError } from "../chargehive";
import { ComponentLoader } from "../AbstractComponent";
import { AutocompleteAddressComponent } from "../components/AutocompleteAddressComponent";
import { EmailVerificationComponent } from "../components/EmailVerification";
import { WideCarouselComponent } from "../components/WideCarouselComponent";

let hasAccount = getContext()["has_account"];
let chiveInst: Chive;
let changedField = false;
let infoSaved = false;
let shippingSaved = false;
let currency = getContext()["currency"] || "GBP";
let checkoutId = getContext()["checkout_id"] || null;
let values = getContext()["values"] || {};
let shippingCost = 2.95;
let shippingCostContext = getContext()["shipping_cost"];

if (shippingCostContext !== null) {
    shippingCost = shippingCostContext;
}

let subscriptionCost = getContext()["subscription_cost"] || 0;
let totalCost = shippingCost + subscriptionCost;
let firedOrderPixel = false;
let buttonElem: JQuery;
let savedContent: string;
let paypalButton: JQuery = $("[data-paypal-button]");
let quantity = getContext()["quantity"] || 1;
let quantityElem = $("[data-quantity]");
let quantityBumpers = $("[data-quantity-bumper]");
let planNameElem = $("[data-plan-name]");
let planPriceElem = $("[data-plan-price]");
let expandSummaryElem = $("[data-expand-summary]");

let petNameValidation = {
    required: true,
    minlength: 2,
    maxlength: 64,
    notInOtherInput: "name",
    noDuplicates: "pet_name",
    lettersOnly: true,
    hasLetters: true,
};

function validate() {
    ($("[data-checkout-form='information']") as any).validate({
        onKeyup: true,
        rules: {
            pet_name_1: petNameValidation,
            pet_name_2: petNameValidation,
            pet_name_3: petNameValidation,
            pet_name_4: petNameValidation,
            pet_name_5: petNameValidation,
            name: {
                required: true,
                fullname: true,
                noTitles: true,
            },
            email: {
                required: true,
                hasAttribute: "data-email-ok",
            },
            phone: {
                required: true,
                dynamicPhone: true,
            },
            info_terms: {
                required: true,
            },
        },
        messages: {
            email: {
                hasAttribute: function(error, element) {
                    let existingError = $(element).next(".error");

                    if (existingError.length && existingError.is(":visible")) {
                        return existingError.text();
                    } else {
                        return "Please enter a valid email address.";
                    }
                },
            },
        },
    } as any);

    ($("[data-checkout-form='cart']") as any).validate({
        onKeyup: true,
        rules: {
            pet_name_1: petNameValidation,
            pet_name_2: petNameValidation,
            pet_name_3: petNameValidation,
            pet_name_4: petNameValidation,
            pet_name_5: petNameValidation,
        },
    } as any);

    ($("[data-checkout-form='shipping']") as any).validate({
        onKeyup: true,
        rules: {
            name: {
                required: true,
                fullname: true,
                noTitles: true,
            },
            email: {
                required: true,
                email: true,
            },
            info_terms: {
                required: true,
            },
            line_1: {
                required: true,
                minlength: 3,
                addressLine: true,
            },
            city: {
                required: true,
                minlength: 3,
                addressLine: true,
            },
            state: {
                required: true,
                minlength: 2,
                addressLine: true,
            },
            postal_code: {
                required: true,
                postcode: true,
            },
        },
    } as any);

    ($("[data-checkout-form='payment']") as any).validate({
        onKeyup: true,
        rules: {
            inline_address: {
                required: true,
                minlength: 3,
            },
            billing_line_1: {
                required: true,
                minlength: 3,
            },
            billing_city: {
                required: true,
                minlength: 3,
            },
            billing_state: {
                required: true,
                minlength: 2,
            },
            billing_postal_code: {
                required: true,
                postcode: true,
            },
            terms: {
                required: true,
            },
        },
    } as any);
}

function setupListeners() {
    $("[data-checkout-submit]").on("click", event => {
        let form = $(event.currentTarget).closest("form");
        form.trigger("submit");
    });

    $("form").on("submit", async event => {
        event.preventDefault();

        let formElem = $(event.currentTarget);
        buttonElem = formElem.find("[data-checkout-submit]");
        savedContent = buttonElem.html();
        buttonElem.html("<div class='loader'></div>");

        if (!(formElem as any).valid()) {
            buttonElem.html(savedContent);
            scrollToError();
            return;
        }

        let formName = formElem.data("checkout-form");
        let result = await processForm(formName);

        if (!result) {
            buttonElem.html(savedContent);
            return;
        }

        let nextForm = formElem.next("form");

        if (nextForm.length) {
            let nextFormName = nextForm.data("checkout-form");
            moveForm(nextFormName);

            if (hasAccount && checkoutId) {
                checkout(checkoutId, values, nextFormName);
            }
        }

        buttonElem.html(savedContent);
    });

    $("[name=billing_same]").on("change", () => {
        $("[data-billing-form]").toggle();
    });

    $("input").on("keyup", event => {
        if (event.key === "Enter") {
            let form = $(event.currentTarget).closest("form");
            form.trigger("submit");
        }
    });

    $("[name]").on("change", event => {
        let formElem = $(event.currentTarget).closest("form");
        let formName = formElem.data("checkout-form");

        if (formName === "information") {
            infoSaved = false;
        } else if (formName === "shipping") {
            shippingSaved = false;
        } else if (formName === "payment") {
            values["billing_saved"] = false;
        }

        if (!changedField) {
            firePixels("InitiateCheckout");
            changedField = true;
        }
    });

    $("[data-move-checkout]").on("click", event => {
        let formName = $(event.currentTarget).data("move-checkout");
        moveForm(formName);
    });

    $(window).on("hashchange", () => {
        let firstForm = $("[data-checkout-form]").first();
        let hash =
            window.location.href.split("#")[1] ||
            firstForm.data("checkout-form");
        moveForm(hash);
    });

    paypalButton.on("click", async () => {
        if (paypalButton.find(".loader").length) {
            return;
        }

        savedContent = paypalButton.html();
        paypalButton.html("<div class='loader'></div>");

        window.location.href = await paypalUrl(
            chiveInst.getProductCodes(),
            `shipping-${values["order_id"]}`
        );
    });

    $("[data-apple-pay]").on("click", async () => {
        chiveInst.setPaymentMethod("PLACEMENT_CAPABILITY_APPLE_PAY");
        await chiveInst.setBilling(values);

        if (chiveInst.inlineAddress) {
            chiveInst.applePayInline = true;
        }

        chiveInst.submit().catch(error => {
            LightboxComponent.getDisplay("Apple Pay failed.", true);
        });
    });

    $("[data-google-pay]").on("click", async () => {
        chiveInst.setPaymentMethod("PLACEMENT_CAPABILITY_GOOGLE_PAY");
        await chiveInst.setBilling(values);
        let paymentTimer = setTimeout(() => {
            LightboxComponent.getDisplay("payment-processing");
        }, 2000);

        chiveInst.submit().catch(error => {
            clearTimeout(paymentTimer);
            LightboxComponent.getDisplay("Payment failed.", true);
        });
    });

    quantityBumpers.on("click", event => {
        let direction = $(event.currentTarget).data("quantity-bumper");

        if (direction == "up") {
            quantity = Math.min(5, quantity + 1);
            addNameField();
        } else {
            quantity = Math.max(1, quantity - 1);
            removeNameField();
        }

        quantityElem.text(quantity);

        let context = getContext();
        let onOffer = context["has_offer"];
        let subscriptionPrices = context["plan_prices"];
        let dynamicDiscount = $("[data-dynamic-discount]");
        let plusShow = $("[data-plus-show]");
        let plusTaller = $("[data-plus-taller]");

        if (quantity == 1) {
            let premiumPrice = subscriptionPrices["premium"];
            planNameElem.text("Crumb Premium");

            if (!onOffer) {
                planPriceElem.text(localPrice(currency, premiumPrice));
                subscriptionCost = premiumPrice;
                totalCost = premiumPrice + shippingCost;
            }

            plusShow.hide();
            plusTaller.addClass("order__summary__section__title--margin-top");

            if (dynamicDiscount.length) {
                dynamicDiscount.addClass(
                    "order__summary__section__right--even-taller"
                );
                dynamicDiscount.removeClass(
                    "order__summary__section__right--renew"
                );
            }
        } else {
            let premiumPlusPrice = subscriptionPrices["premium_plus"];
            planNameElem.text("Crumb Premium Plus");

            if (!onOffer) {
                planPriceElem.text(localPrice(currency, premiumPlusPrice));
                subscriptionCost = premiumPlusPrice;
                totalCost = premiumPlusPrice + shippingCost;
            }

            plusShow.show();
            plusTaller.removeClass(
                "order__summary__section__title--margin-top"
            );

            if (dynamicDiscount.length) {
                dynamicDiscount.removeClass(
                    "order__summary__section__right--even-taller"
                );
                dynamicDiscount.addClass(
                    "order__summary__section__right--renew"
                );
                let beforeCost = 2.95 * quantity;
                dynamicDiscount.html(
                    `<span>${localPrice(
                        currency,
                        beforeCost
                    )}</span>${localPrice(currency, premiumPlusPrice)}`
                );
            }
        }
    });

    expandSummaryElem.on("click", event => {
        $("[data-summary]").slideToggle();

        if (expandSummaryElem.hasClass("expanded")) {
            expandSummaryElem.removeClass("expanded");
        } else {
            expandSummaryElem.addClass("expanded");
        }
    });

    $("[data-add-another-pet]").on("click", event => {
        addNameField(true);
    });

    $("body").on("click", "[data-remove-pet]", event => {
        let petId = $(event.currentTarget).attr("data-remove-pet");
        removeNameField(parseInt(petId));
    });

    $("[data-checkout-option]").on("click", event => {
        let option = $(event.currentTarget).data("checkout-option");
        let price = $(event.currentTarget).data("option-price");
        $(".selected").removeClass("selected");
        $(event.currentTarget).addClass("selected");
        chiveInst.products[0].code = chiveInst.options[option];
        planPriceElem.text(price);
    });
}

function setupChiveListeners() {
    chiveInst.on("success", async () => {
        if (getContext()["vet_first"]) {
            await LightboxComponent.getDisplay("vet-redirect", false, 2000);

            let redirect = new URL(
                "/account/setup-redirect",
                window.location.href
            );
            redirect.searchParams.set("fire_pixel", "CompletePayment");

            let ttclid = getUrlParam("ttclid");

            if (ttclid) {
                redirect.searchParams.set("ttclid", getUrlParam("ttclid"));
            }

            window.location.href = redirect.toString();
            return;
        }

        let thankYouUrl = "/thank-you";
        let ttclid = getUrlParam("ttclid");

        if (ttclid) {
            thankYouUrl += `?ttclid=${ttclid}`;
        }

        window.location.href = thankYouUrl;
    });

    chiveInst.on("declined", reason => {
        LightboxComponent.getDisplay(
            `Sorry, your payment was declined:<p class="lightbox__title">"${reason}"</p> Please try another payment method.`,
            true
        );
        buttonElem.html(savedContent);
    });

    chiveInst.on("error", () => {
        LightboxComponent.getDisplay(
            "Transaction failed. Please try again.",
            true
        );
        buttonElem.html(savedContent);
    });

    chiveInst.on("cancel", () => {
        LightboxComponent.getDisplay(
            "Transaction cancelled. Please try again.",
            true
        );
        buttonElem.html(savedContent);
    });

    chiveInst.on("token", async () => {
        await LightboxComponent.getDisplay("vet-redirect", false, 2000);

        let redirect = new URL("/account/setup-redirect", window.location.href);
        redirect.searchParams.set("fire_pixel", "CompletePayment");

        let ttclid = getUrlParam("ttclid");

        if (ttclid) {
            redirect.searchParams.set("ttclid", getUrlParam("ttclid"));
        }

        window.location.href = redirect.toString();
        return;
    });
}

function moveForm(formName: string) {
    let allForms = $("[data-checkout-form]");
    let targetForm = $(`[data-checkout-form='${formName}']`);
    let activeForm = $("form.active");

    if (formName == activeForm.data("checkoutForm")) {
        return;
    }

    let forward = allForms.index(targetForm) > allForms.index(activeForm);

    activeForm.animate({ opacity: "0" }, { duration: 500 });
    activeForm.removeClass("active");
    targetForm.css("opacity", "0");
    targetForm.addClass("active");
    targetForm.animate({ opacity: "1" }, { duration: 500 });

    let targetProgress = $(`[data-checkout-progress="${formName}"]`);
    let activeProgress = $("[data-checkout-progress].active");
    activeProgress.removeClass("active");
    targetProgress.addClass("active");
    targetProgress.removeClass("complete");

    if (forward) {
        activeProgress.addClass("complete");
    }

    history.pushState(null, null, `#${formName}`);
    scrollToTop();
}

async function processForm(formName: string): Promise<boolean> {
    if (formName === "information") {
        const emailElm = $("[name=email]");
        if (emailElm.attr("data-email-checking")) {
            await sleep(100);
            return processForm(formName);
        }

        if (!(<any>emailElm).valid()) {
            return false;
        }

        let petNames = getPetNames();

        for (let i = 0; i < petNames.length; i++) {
            values[`pet_name_${i + 1}`] = petNames[i];
        }

        let billingNext = $(`[data-checkout-form="information"]`).is(
            "[data-billing-next]"
        );

        if (billingNext) {
            await createAccountSave(true);
        } else if (!infoSaved) {
            await createAccountSave();
        }

        replacePlaceholders($(".checkout__main"), values);

        if (buttonElem) {
            buttonElem.html(savedContent);
        }

        if (!chiveInst) {
            chiveInst = new Chive();
            setupChiveListeners();
        }

        if (billingNext) {
            await chiveInst.setupCharge();
            await chiveInst.setPersonalInfo(values["email"], values["name"]);
        }
    } else if (formName === "cart") {
        let petNames = getPetNames();

        for (let i = 0; i < petNames.length; i++) {
            values[`pet_name_${i + 1}`] = petNames[i];
        }
    } else if (formName === "shipping") {
        if (!hasAccount) {
            await createAccountSave(true);
        }

        if (!chiveInst) {
            chiveInst = new Chive();
            setupChiveListeners();
        }

        let emailInput = $("[data-checkout-form='shipping'] [name=email]");
        if (emailInput.length) {
            values["email"] = emailInput.val() as string;
            await email(values["email"]);
        }

        values["line_1"] = $("[name=line_1]").val() as string;
        values["line_2"] = $("[name=line_2]").val() as string;
        values["city"] = $("[name=city]").val() as string;
        values["state"] = $("[name=state]").val() as string;
        values["postal_code"] = $("[name=postal_code]").val() as string;
        values["verify_token"] = $("[name=verify_token]").val() as string;

        if (!shippingSaved) {
            await address(
                "Owner",
                values["line_1"],
                values["city"],
                values["state"],
                values["postal_code"],
                true,
                false,
                null,
                values["line_2"],
                values["verify_token"]
            );
            shippingSaved = true;
        }

        let totalElem = $("[data-checkout-total]");
        let shippingElem = $("[data-checkout-shipping]");
        let shippingNice = localPrice(currency, shippingCost);

        if (shippingCost === 0) {
            shippingNice = "FREE";
        }

        if (totalCost !== subscriptionCost) {
            totalElem.text(localPrice(currency, totalCost));
        }

        totalElem.removeClass("order__summary__calculated");
        shippingElem.text(shippingNice);
        shippingElem.removeClass("order__summary__calculated");

        await chiveInst.setupCharge();
        await chiveInst.setPersonalInfo(values["email"], values["name"]);

        if (buttonElem) {
            buttonElem.html(savedContent);
        }

        $("[data-claimed-today]")
            .delay(1000)
            .fadeIn()
            .delay(7500)
            .fadeOut();
    } else if (formName === "payment") {
        let paymentTimer = setTimeout(() => {
            LightboxComponent.getDisplay("payment-processing");
        }, 2000);

        let inlineAddress = $("[name=inline_address]");

        if (inlineAddress.length && !values["billing_saved"]) {
            values["billing_line_1"] = inlineAddress.val() as string;
            values["billing_postal_code"] = $(
                "[name=billing_postal_code]"
            ).val() as string;

            let addressLazyResponse = await addressLazy(
                values["billing_line_1"],
                values["billing_postal_code"]
            );

            values["billing_city"] = addressLazyResponse["city"];
            values["billing_state"] = addressLazyResponse["state"];

            values["billing_saved"] = true;
        }

        await chiveInst.setBilling(values);

        if (!firedOrderPixel) {
            firePixels("PlaceAnOrder");
            firedOrderPixel = true;
        }

        await chiveInst.submit().catch(error => {
            buttonElem.html(savedContent);
            scrollToError();
            clearTimeout(paymentTimer);
            LightboxComponent.getHide("payment-processing");

            if (error instanceof ChiveError) {
                if (error["fields"].length) {
                    for (let item of error["fields"]) {
                        Chive.addError(item);
                    }
                } else {
                    LightboxComponent.getDisplay(error.message, true);
                }

                return;
            } else {
                throw error;
            }
        });
    } else {
        throw new Error("Unknown form name: " + formName);
    }

    return true;
}

async function createAccountSave(waitForFortifi: boolean = false) {
    values["name"] = $("[name=name]").val() as string;
    values["first_name"] = values["name"].split(" ")[0];
    values["email"] = $("[name=email]").val() as string;
    values["phone"] = $("[name=phone]").val() as string;
    values["hide_name"] = $("[name=hide_name]").prop("checked");

    if (hasAccount) {
        await deleteAccount().catch(() => {});
    }

    let createResponse = await createAccount(
        getPetNames(),
        values["name"],
        values["email"],
        values["phone"],
        values["hide_name"],
        currency,
        getContext()["vet_first"],
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        waitForFortifi,
        values
    ).catch(error => {
        if (error.response.status === 403) {
            LightboxComponent.getDisplay(
                "This email address is already in use. Please use a different email address or log in to your existing account.",
                true
            );
        } else {
            LightboxComponent.getDisplay(
                "There was an error creating your account. Please try again later.",
                true
            );
        }

        $("[name=email]").addClass("error");
        buttonElem.html(savedContent);

        return false;
    });

    if (!createResponse) {
        return;
    }

    checkoutId = createResponse["checkout_id"];
    values["order_id"] = createResponse["order_id"];

    if (checkoutId) {
        const url = new URL(window.location.href);
        url.searchParams.set("id", checkoutId);
        window.history.replaceState(null, null, url.toString());
    }

    infoSaved = true;
    hasAccount = true;

    firePixels("Lead");
}

function getPetNames(): string[] {
    let petNameInputs = $("[name*=pet_name]").not("[lightbox-component] *");
    let petNames: string[] = [];

    petNameInputs.each((index, element) => {
        petNames.push($(element).val() as string);
    });

    for (let i = 0; i <= 5; i++) {
        let petNameParam = getUrlParam(`pet_name_${i + 1}`);
        if (petNameParam) {
            petNames.push(petNameParam);
        }
    }

    return petNames;
}

async function checkReturn(): Promise<void> {
    let intendedForm = getContext()["form"];

    if (!intendedForm) {
        return;
    }

    for (let key of Object.keys(values)) {
        $(`[name=${key}]`).val(values[key]);
    }

    infoSaved = true;

    if (intendedForm !== "information" && intendedForm !== "cart") {
        chiveInst = new Chive();
        setupChiveListeners();
    }

    if (intendedForm === "payment") {
        shippingSaved = true;
        $("[data-checkout-progress=shipping]").addClass("complete");
        await processForm("shipping");
    }

    replacePlaceholders($(".checkout__main"), values);
    moveForm(intendedForm);
    toggleLoader();
}

function toggleLoader(): void {
    $("[data-checkout-loader]").toggle();
}

export async function setup() {
    validate();
    setupListeners();

    await checkReturn();

    new ComponentLoader([
        AutocompleteAddressComponent,
        EmailVerificationComponent,
        WideCarouselComponent,
    ]);

    if (!hasAccount) {
        firePixels("AddToCart");
    }
}

function getNameInputs(): JQuery<HTMLElement> {
    return $("[name^='pet_name']").not("[lightbox-component] *");
}

function addNameField(canDelete: boolean = false): void {
    let nameFields = getNameInputs();
    let fieldNumber = nameFields.length;

    if (fieldNumber == 0 || fieldNumber >= 5) {
        return;
    }

    let dupeHTML = nameFields[0].outerHTML.replace(
        new RegExp(`pet_name_1`, "g"),
        `pet_name_${fieldNumber + 1}`
    );
    let dupeElem = $(dupeHTML);

    dupeElem.attr("placeholder", `${ordinalSuffix(fieldNumber + 1)} Pet Name`);

    nameFields.last().after(dupeElem);

    if (canDelete) {
        dupeElem.addClass("checkout__input--can-delete");
        nameFields
            .first()
            .before(
                `<i class="checkout__delete fas fa-times" data-remove-pet="${fieldNumber +
                    1}"></i>`
            );
    }

    if (fieldNumber == 1) {
        nameFields.first().attr("placeholder", "1st Pet Name");
    } else if (fieldNumber == 4) {
        $("[data-add-another-pet]").hide();
    }
}

function removeNameField(petId?: number): void {
    let nameFields = getNameInputs();
    let fieldNumber = nameFields.length;

    if (fieldNumber == 1) {
        return;
    }

    if (petId) {
        nameFields.get(petId - 1).remove();
        let removeElems = $("[data-remove-pet]");
        nameFields = getNameInputs();

        for (let i = 0; i < nameFields.length; i++) {
            let elem = nameFields.get(i);
            let newId = i + 1;
            let newName = `pet_name_${newId}`;
            let newPlaceholder = `${ordinalSuffix(newId)} Pet Name`;

            $(elem).attr("name", newName);
            $(elem).attr("placeholder", newPlaceholder);

            let deleteElem = removeElems.get(i);
            $(deleteElem).attr("data-remove-pet", newId + 1);
        }

        removeElems.last().remove();
    } else {
        nameFields.last().remove();
    }

    if (fieldNumber == 2) {
        nameFields.first().attr("placeholder", "Pet Name");
    }

    $("[data-add-another-pet]").show();
}
